<template>
    <legend class="w-full flex justify-between items-center mb-6 pb-4 border-b-2 border-b-gray-200">
        <span class="text-2xl font-bold text-accent">
            <slot />
        </span>
        <a v-if="props.helpLink" :href="props.helpLink" target="_blank">
            <i class="fas fa-circle-question" />
        </a>
    </legend>
</template>
<script setup lang="ts">
const props = defineProps<{ helpLink?: string }>();
</script>
